<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-merchants-set-main"
  >
    <div class="w-aim-head">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          :to="{ path: '/implement' }"
        >招商实施管理</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">招商实施设置</span>
      </div>
      <span
        class="add-btn"
        @click="merchantsSetData"
        v-if="hasPermissionAdd"
      >添加</span>
    </div>
    <div v-if="hasPermissionShow">
      <div class="w-singed-table">
        <table>
          <thead>
            <tr>
              <td>项目</td>
              <td>跟进人员</td>
              <td>计划时间</td>
              <td>创建人</td>
              <td>创建时间</td>
              <td>修改人</td>
              <td>修改时间</td>
              <td
                width="120"
                v-if="hasPermissionDelete || hasPermissionEdit"
              >操作</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, inx) in dataList"
              :key="inx"
            >
              <td>
                <span
                  v-for="(item2, inx2) in item.project"
                  :key="inx2"
                >{{item2.label}}</span>
              </td>
              <td>{{item.followUser | typeFilter}}</td>
              <td>{{item.planTime | typeFilter}}</td>
              <td>{{item.createUserName}}</td>
              <td>{{item.createTime}}</td>
              <td>{{item.updateUserName}}</td>
              <td>{{item.updateTime}}</td>
              <td
                class="w-main-color"
                v-if="hasPermissionDelete || hasPermissionEdit"
              >
                <span
                  @click="editData(item)"
                  v-if="hasPermissionEdit"
                >修改</span>
                <span
                  @click="deleteData(item)"
                  v-if="hasPermissionDelete"
                >删除</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="no-recommend-brand"
          style="padding: 80px; 0"
          v-if="notData"
        >
          <img src="@/assets/images/public/not-plandata.png">
          <p>暂无相关数据</p>
        </div>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <el-dialog
      :visible.sync="merchantsSetDialog"
      custom-class="merchants-set-dialog"
      width="35%"
      top="20vh"
    >
      <h2>招商实施设置</h2>
      <p class="dialog-tips">可配置对应项目发布招商任务时跟进人员、计划时间的是否必填</p>
      <el-form
        ref="form"
        :model="form"
        label-position="right"
      >
        <el-form-item
          label="项目："
          label-width="100px"
        >
          <div class="w-input-sty">
            <el-select
              v-model="projectSelect"
              placeholder="请选择"
              filterable
              multiple
              collapse-tags
              :loading="selectLoading"
              popper-class="w-block-select-down"
              @change="selectData"
              :disabled="isEdit"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="跟进人员："
          label-width="100px"
        >
          <el-radio-group v-model="form.followUser">
            <el-radio :label="0">选填</el-radio>
            <el-radio :label="1">必填</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="计划时间："
          label-width="100px"
        >
          <el-radio-group v-model="form.planTime">
            <el-radio :label="0">选填</el-radio>
            <el-radio :label="1">必填</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="merchantsSetDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitData"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="confirmDialog"
      custom-class="confirm-dialog"
      width="20%"
      top="35vh"
    >
      <p style="color: #FFF; text-align:center">请确认是否删除</p>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="confirmDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitDelete"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      selectLoading: false,
      merchantsSetDialog: false,
      confirmDialog: false,
      dialogType: '',
      options: [],
      projectSelect: [],
      form: {
        project: [{
          label: '',
          value: null
        }],
        followUser: 0,
        planTime: 0
      },
      dataList: [],
      notData: false,
      isEdit: false,
      hasPermissionShow: false,
      hasPermissionAdd: false,
      hasPermissionEdit: false,
      hasPermissionDelete: false
    }
  },
  filters: {
    typeFilter (val) {
      return val === 1 ? '必填' : '选填'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  created () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 189
    })
    if (this.userAuthorities.length) {
      this.hasPermissionShow = this.userAuthorities[0].func.indexOf('show') !== -1
      this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
      this.hasPermissionDelete = this.userAuthorities[0].func.indexOf('del') !== -1
      if (this.hasPermissionShow) {
        this.init()
      }
    }
    this.axios.post(api.getBerthProjectList).then((res) => {
      this.options = res.data.data
      this.options.unshift({ projectName: '全部项目', id: 0 })
    })
  },
  methods: {
    init () {
      this.loading = true
      this.axios.post(api.merchantsActualizeConfigureList).then((res) => {
        this.loading = false
        this.dataList = []
        if (res.data.data.length === 0) {
          this.notData = true
        } else {
          this.dataList = res.data.data
          this.notData = false
        }
      })
    },
    // 添加
    merchantsSetData () {
      this.merchantsSetDialog = true
      this.isEdit = false
      this.dialogType = 'add'
      this.form = this.$options.data().form
      this.projectSelect = [0]
    },
    // 选择项目
    selectData (data) {
      this.form.project = []
      if (this.projectSelect[0] === 0 && this.projectSelect.length !== 1) {
        this.projectSelect.shift()
      } else if (this.projectSelect[this.projectSelect.length - 1] === 0) {
        this.projectSelect = [0]
      }
      this.options.map((item, inx) => {
        if (this.projectSelect[0] === 0) {
          if (inx !== 0) {
            this.form.project.push({ label: item.projectName, value: item.id })
          }
        } else {
          data.map(item2 => {
            if (item.id === item2) {
              this.form.project.push({ label: item.projectName, value: item.id })
            }
          })
        }
      })
    },
    // 编辑
    editData (item) {
      this.merchantsSetDialog = true
      this.isEdit = true
      this.dialogType = 'edit'
      this.form = JSON.parse(JSON.stringify(item))
      this.projectSelect = this.form.project.map(item => {
        return item.value
      })
    },
    submitData () {
      var apiStr = ''
      var form = this.form
      if (this.projectSelect.length === 0) {
        this.$message.error('请选择项目')
        return
      }
      if (this.projectSelect[0] === 0) {
        form.project = []
        this.options.map((item, inx) => {
          if (inx !== 0) {
            form.project.push({
              label: item.projectName,
              value: item.id
            })
          }
        })
      }
      if (this.dialogType === 'edit') {
        apiStr = api.merchantsActualizeConfigureUpdate
      } else {
        apiStr = api.merchantsActualizeConfigure
      }
      this.loading = true
      this.axios.post(apiStr, {
        followUser: form.followUser,
        planTime: form.planTime,
        project: form.project
      }).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.merchantsSetDialog = false
          this.init()
        }
      })
    },
    // 删除
    deleteData (item) {
      this.confirmDialog = true
      this.form = item
    },
    submitDelete () {
      this.loading = true
      this.axios.post(api.merchantsActualizeConfigureDelete, {
        followUser: this.form.followUser,
        planTime: this.form.planTime,
        project: this.form.project
      }).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.init()
        } else {
          this.$message.error(res.data.msg)
        }
        this.confirmDialog = false
      })
    }
  }
}
</script>

<style lang="stylus">
.w-merchants-set-main
  .merchants-set-dialog, .confirm-dialog
    background #272930
    h2
      color #FFF
      font-size 18px
      font-weight 600
    .dialog-tips
      color #606266
      margin 5px 0 30px
    .dialog-footer
      text-align center
      .el-button
        width 125px
    .el-select
      width 260px
</style>
<style lang="stylus" scoped>
.w-merchants-set-main
  margin-top 10px
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-aim-head
    display flex
    height 34px
    position relative
  .add-btn
    position absolute
    right 0
    bottom 0
    color #FFF
    font-size 12px
    padding 5px 35px
    border 1px solid #FFF
    border-radius 2px
    cursor pointer
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding 0 24px
    padding-bottom 35px
    table
      width 100%
      thead
        tr
          height 54px
          line-height 54px
          color #999
          font-size 12px
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            padding-left 10px
      tbody
        tr
          height 47px
          line-height 47px
          color #ffffff
          font-size 12px
          &:hover td
            background-color #23252b
          td
            border none
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            line-height 24px
            padding-left 10px
            span
              margin-right 10px
              cursor pointer
            .status
              display inline-block
              width 10px
              height 10px
              border-radius 5px
              background #FFA134
              margin-right 5px
</style>
